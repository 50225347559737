define("discourse/plugins/discourse-presence/discourse/components/topic-presence-display", ["exports", "@ember/component", "@ember/object/computed", "@ember/service", "@ember-decorators/object", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _computed, _service, _object, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldDisplay}}
    <div class="presence-users">
      <div class="presence-avatars">
        {{#each this.users as |user|}}
          <UserLink @user={{user}}>
            {{avatar user imageSize="small"}}
          </UserLink>
        {{/each}}
      </div>
      <span class="presence-text">
        <span class="description">
          {{i18n "presence.replying_to_topic" count=this.users.length}}
        </span>
        <span class="wave">
          <span class="dot">.</span>
          <span class="dot">.</span>
          <span class="dot">.</span>
        </span>
      </span>
    </div>
  {{/if}}
  */
  {
    "id": "4V683/rb",
    "block": "[[[41,[30,0,[\"shouldDisplay\"]],[[[1,\"  \"],[10,0],[14,0,\"presence-users\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"presence-avatars\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"users\"]]],null]],null],null,[[[1,\"        \"],[8,[39,3],null,[[\"@user\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,4],[[30,1]],[[\"imageSize\"],[\"small\"]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"presence-text\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"description\"],[12],[1,\"\\n        \"],[1,[28,[35,5],[\"presence.replying_to_topic\"],[[\"count\"],[[30,0,[\"users\",\"length\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"wave\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[1,\"\\n        \"],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[1,\"\\n        \"],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"user\"],false,[\"if\",\"each\",\"-track-array\",\"user-link\",\"avatar\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-presence/discourse/components/topic-presence-display.hbs",
    "isStrictMode": false
  });
  class TopicPresenceDisplay extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "presence", [_service.service]))();
    #presence = (() => (dt7948.i(this, "presence"), void 0))();
    topic = null;
    replyChannel = null;
    whisperChannel = null;
    static #_2 = (() => dt7948.g(this.prototype, "users", [(0, _computed.union)("replyUsers", "whisperUsers")]))();
    #users = (() => (dt7948.i(this, "users"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "shouldDisplay", [(0, _computed.gt)("users.length", 0)]))();
    #shouldDisplay = (() => (dt7948.i(this, "shouldDisplay"), void 0))();
    replyUsers(users) {
      return users?.filter(u => u.id !== this.currentUser.id);
    }
    static #_4 = (() => dt7948.n(this.prototype, "replyUsers", [(0, _decorators.default)("replyChannel.users.[]")]))();
    whisperUsers(users) {
      return users?.filter(u => u.id !== this.currentUser.id);
    }
    static #_5 = (() => dt7948.n(this.prototype, "whisperUsers", [(0, _decorators.default)("whisperChannel.users.[]")]))();
    replyChannelName(id) {
      return `/discourse-presence/reply/${id}`;
    }
    static #_6 = (() => dt7948.n(this.prototype, "replyChannelName", [(0, _decorators.default)("topic.id")]))();
    whisperChannelName(id) {
      return `/discourse-presence/whisper/${id}`;
    }
    static #_7 = (() => dt7948.n(this.prototype, "whisperChannelName", [(0, _decorators.default)("topic.id")]))();
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      if (this.replyChannel?.name !== this.replyChannelName) {
        this.replyChannel?.unsubscribe();
        this.set("replyChannel", this.presence.getChannel(this.replyChannelName));
        this.replyChannel.subscribe();
      }
      if (this.currentUser.staff && this.whisperChannel?.name !== this.whisperChannelName) {
        this.whisperChannel?.unsubscribe();
        this.set("whisperChannel", this.presence.getChannel(this.whisperChannelName));
        this.whisperChannel.subscribe();
      }
    }
    _destroyed() {
      this.replyChannel?.unsubscribe();
      this.whisperChannel?.unsubscribe();
    }
    static #_8 = (() => dt7948.n(this.prototype, "_destroyed", [(0, _object.on)("willDestroyElement")]))();
  }
  _exports.default = TopicPresenceDisplay;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TopicPresenceDisplay);
});