define("discourse/plugins/discourse-presence/discourse/components/composer-presence-display", ["exports", "@ember/component", "@ember/object/computed", "@ember/service", "@ember-decorators/component", "@ember-decorators/object", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _computed, _service, _component2, _object, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldDisplay}}
    <div class="presence-users">
      <div class="presence-avatars">
        {{#each this.presenceUsers as |user|}}
          {{avatar user imageSize="small"}}
        {{/each}}
      </div>
      <span class="presence-text">
        <span class="description">
          {{~#if this.isReply~}}
            {{i18n "presence.replying" count=this.presenceUsers.length}}
          {{~else~}}
            {{i18n "presence.editing" count=this.presenceUsers.length}}
          {{~/if~}}
        </span>
        <span class="wave">
          <span class="dot">.</span>
          <span class="dot">.</span>
          <span class="dot">.</span>
        </span>
      </span>
    </div>
  {{/if}}
  */
  {
    "id": "fKramLOc",
    "block": "[[[41,[30,0,[\"shouldDisplay\"]],[[[1,\"  \"],[10,0],[14,0,\"presence-users\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"presence-avatars\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"presenceUsers\"]]],null]],null],null,[[[1,\"        \"],[1,[28,[35,3],[[30,1]],[[\"imageSize\"],[\"small\"]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"presence-text\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"description\"],[12],[41,[30,0,[\"isReply\"]],[[[1,[28,[35,4],[\"presence.replying\"],[[\"count\"],[[30,0,[\"presenceUsers\",\"length\"]]]]]]],[]],[[[1,[28,[35,4],[\"presence.editing\"],[[\"count\"],[[30,0,[\"presenceUsers\",\"length\"]]]]]]],[]]],[13],[1,\"\\n      \"],[10,1],[14,0,\"wave\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[1,\"\\n        \"],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[1,\"\\n        \"],[10,1],[14,0,\"dot\"],[12],[1,\".\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"user\"],false,[\"if\",\"each\",\"-track-array\",\"avatar\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-presence/discourse/components/composer-presence-display.hbs",
    "isStrictMode": false
  });
  const ComposerPresenceDisplay = dt7948.c(class ComposerPresenceDisplay extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "presence", [_service.service]))();
    #presence = (() => (dt7948.i(this, "presence"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "composerPresenceManager", [_service.service]))();
    #composerPresenceManager = (() => (dt7948.i(this, "composerPresenceManager"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "isReply", [(0, _computed.equal)("state", "reply")]))();
    #isReply = (() => (dt7948.i(this, "isReply"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "isEdit", [(0, _computed.equal)("state", "edit")]))();
    #isEdit = (() => (dt7948.i(this, "isEdit"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "isWhisper", [(0, _computed.equal)("state", "whisper")]))();
    #isWhisper = (() => (dt7948.i(this, "isWhisper"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "replyingUsers", [(0, _computed.union)("replyChannel.users", "whisperChannel.users")]))();
    #replyingUsers = (() => (dt7948.i(this, "replyingUsers"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "editingUsers", [(0, _computed.readOnly)("editChannel.users")]))();
    #editingUsers = (() => (dt7948.i(this, "editingUsers"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "shouldDisplay", [(0, _computed.gt)("presenceUsers.length", 0)]))();
    #shouldDisplay = (() => (dt7948.i(this, "shouldDisplay"), void 0))();
    state(replyingToTopic, editingPost, whisper, composerOpen) {
      if (!composerOpen) {
        return;
      } else if (editingPost) {
        return "edit";
      } else if (whisper) {
        return "whisper";
      } else if (replyingToTopic) {
        return "reply";
      }
    }
    static #_9 = (() => dt7948.n(this.prototype, "state", [(0, _decorators.default)("model.replyingToTopic", "model.editingPost", "model.whisper", "model.composerOpened")]))();
    replyChannelName(topicId, isReply, isWhisper) {
      if (topicId && (isReply || isWhisper)) {
        return `/discourse-presence/reply/${topicId}`;
      }
    }
    static #_10 = (() => dt7948.n(this.prototype, "replyChannelName", [(0, _decorators.default)("model.topic.id", "isReply", "isWhisper")]))();
    whisperChannelName(topicId, isReply, isWhisper) {
      if (topicId && this.currentUser.whisperer && (isReply || isWhisper)) {
        return `/discourse-presence/whisper/${topicId}`;
      }
    }
    static #_11 = (() => dt7948.n(this.prototype, "whisperChannelName", [(0, _decorators.default)("model.topic.id", "isReply", "isWhisper")]))();
    editChannelName(isEdit, postId) {
      if (isEdit) {
        return `/discourse-presence/edit/${postId}`;
      }
    }
    static #_12 = (() => dt7948.n(this.prototype, "editChannelName", [(0, _decorators.default)("isEdit", "model.post.id")]))();
    _setupChannel(channelKey, name) {
      if (this[channelKey]?.name !== name) {
        this[channelKey]?.unsubscribe();
        if (name) {
          this.set(channelKey, this.presence.getChannel(name));
          this[channelKey].subscribe();
        } else if (this[channelKey]) {
          this.set(channelKey, null);
        }
      }
    }
    _setupChannels() {
      this._setupChannel("replyChannel", this.replyChannelName);
      this._setupChannel("whisperChannel", this.whisperChannelName);
      this._setupChannel("editChannel", this.editChannelName);
    }
    static #_13 = (() => dt7948.n(this.prototype, "_setupChannels", [(0, _object.observes)("replyChannelName", "whisperChannelName", "editChannelName")]))();
    _cleanupChannels() {
      this._setupChannel("replyChannel", null);
      this._setupChannel("whisperChannel", null);
      this._setupChannel("editChannel", null);
    }
    presenceUsers(isReply, replyingUsers, editingUsers) {
      const users = isReply ? replyingUsers : editingUsers;
      return users?.filter(u => u.id !== this.currentUser.id)?.slice(0, this.siteSettings.presence_max_users_shown);
    }
    static #_14 = (() => dt7948.n(this.prototype, "presenceUsers", [(0, _decorators.default)("isReply", "replyingUsers.[]", "editingUsers.[]")]))();
    subscribe() {
      this._setupChannels();
    }
    static #_15 = (() => dt7948.n(this.prototype, "subscribe", [(0, _object.on)("didInsertElement")]))();
    _contentChanged() {
      if (this.model.reply === "") {
        return;
      }
      const entity = this.state === "edit" ? this.model?.post : this.model?.topic;
      this.composerPresenceManager.notifyState(this.state, entity?.id);
    }
    static #_16 = (() => dt7948.n(this.prototype, "_contentChanged", [(0, _object.observes)("model.reply", "state", "model.post.id", "model.topic.id")]))();
    closeComposer() {
      this._cleanupChannels();
      this.composerPresenceManager.leave();
    }
    static #_17 = (() => dt7948.n(this.prototype, "closeComposer", [(0, _object.on)("willDestroyElement")]))();
  }, [(0, _component2.tagName)("")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ComposerPresenceDisplay);
});